
@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,900);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#connectwallet {
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  justify-items: center;
  position: relative;
  width: 13vw;
  height: clamp(30px,3vw, 1200px);
  background-color: transparent;
  font-size: 1.25vw;
  font-weight: 500;
  

}

#mintbutton
{
  font-size: 1.25vw;
  font-weight: 500;
  display: flex;
  justify-content: center;
  justify-items: center;
  position: relative;
  width: 13vw;
  
  height: clamp(40px,4.4vw, 1200px);
  color: #fff;
  background-color: transparent;
  outline: 2px solid;
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
}


#connectwallet {
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 0px;
  text-shadow: none;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
}

#connectwallet:hover {
  border: 1px solid;
  background-color: transparent;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
  outline-offset: 15px;
  outline-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 2px #ffffff;
}
#mintbutton:hover {
  border: 1px solid;
  background-color: transparent;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
  outline-offset: 15px;
  outline-color: rgba(255, 255, 255, 0);
  text-shadow: 1px 1px 2px #ffffff;
}
